.container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: linear-gradient(to left bottom, var(--clr-dark-secondary) 50%, var(--clr-dark) 50%);
}

.card {
    display: flex;
    flex-direction: column;
    background-color: var(--clr-dark-accent);
    padding: 48px;
    text-align: center;
    color: #fff;
}

.logoSpotify {
    max-width: 318px;
    margin: auto;
}

.btnSpotify {
    padding: 16px;
    background-color: #18d860;
    text-align: center;
    text-transform: uppercase;
    font-size: 1.5em;
    font-family: "Poppins", sans-serif;
    border: none;
    font-weight: bold;
    letter-spacing: 1px;
    color: #fff;
}

.login {
    width: 318px;
    margin-top: 32px;
    text-decoration: none;
}