.container {
    width: 100%;
    position: relative;
    min-height: 100vh;
}

.playlistDetail {
    display: flex;
    max-width: 60%;
    flex-direction: column;
    margin: 0 auto;
    position: relative;
    padding-bottom: 140px;
}

.btnReturn {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    background-color: var(--clr-dark-accent);
    left: 20px;
    top: 10px;
}

.upBtn {
    position: fixed;
    right: 30px;
    color: white;
    rotate: 45deg;
}
