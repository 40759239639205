.playerContainer {
    display: flex;
    position: fixed;
    width: 100%;
    left: 0;
    right: 0;
    bottom: 0;
    min-height: 100px;
}

.player {
    display: flex;
    flex: 1;
    flex-direction: row;
    background-color: #000;
    padding: 8px 38px;
    line-height: 24px;
    color: #fff;
    position: relative;
    font-family: 'Poppins', sans-serif;

}

.info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 35%;
    margin-top: 8px;
    margin-bottom: 8px;
    z-index: 1;
}

.title {
    font-size: 1.25em;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.description {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.controls {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.play {
    width: 64px;
    height: 64px;
    margin-left: 16px;
    margin-right: 16px;
    border: none;
    border-radius: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.skip {
    background-color: transparent;
    width: 56px;
    height: 56px;
    border: none;
}