.playlist {
    display: flex;
    flex-direction: column;
    flex-basis: 27%;
    min-height: 100%;
}

.info {
    color: #FFF;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 5px;
    margin-bottom: 0;
}

.info h5 {
    margin: 0;
    font-family: 'Poppins', sans-serif;
    font-size: 1em;
}

.info span {
    margin: 0;
    font-family: 'Poppins', sans-serif;
    font-size: 1em;
    font-weight: 400;
}

.spotifyBtn {
    align-self: flex-start;
    padding: 1em;
    height: 100%;
    margin-left: 12px;
    background-color: var(--clr-spotify-green);
    border: none;
    color: #fff;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
}
