.playlistTrack {
    background-color: var(--clr-dark-accent);
    padding: 20px;
    min-width: 85%;
    max-width: 85%;
    margin: 10px auto;
    font-family: "Poppins", sans-serif;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 100px;
}

.playlistTrack p, h4 {
    color: white;
    margin: 0;
}

h4 {
    font-size: 1.5em;
}

.playlistTrack p {
    font-size: 1em;
}

.infoContainer {
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;;
}

.info {
    margin-left: 20px;
}