.bg {
    min-height: 100vh;
    background: linear-gradient(to left bottom, var(--clr-dark-secondary) 50%, var(--clr-dark) 50%);
}

.container {
    max-width: 80%;
    margin: 0 auto;
    padding-top: 2em;
    padding-bottom: 10em;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 2em;
    justify-content: space-evenly;
}