.playButton {
    margin-left: 10px;
    height: 64px;
    width: 64px;
}

.btn {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #18d860;
    border: none;
    height: 100%;
    width: 100%;
}