.container {
    height: 100vh;
    display: flex;
}

.imgContainer {
    display: flex;
    max-height: 80%;
    justify-content: center;
    margin: 2rem auto 0;
}

.imgContainer img {
    width: 100%;
    object-fit: contain;
    height: auto;
}

.btnReturn {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    background-color: var(--clr-dark-accent);
    left: 20px;
    top: 10px;
}